var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: { invisible: _vm.isInvisible } }, [
      _c("p", { staticClass: "text-right text-xs" }, [
        _c(
          "span",
          {
            class: {
              "text-danger": _vm.checkItemLength(_vm.item) > _vm.maxLength,
              "text-success": _vm.checkItemLength(_vm.item) <= _vm.maxLength
            }
          },
          [_vm._v(_vm._s(_vm.checkItemLength(_vm.item)))]
        ),
        _vm._v(" /" + _vm._s(_vm.maxLength) + " " + _vm._s(_vm.maxChars) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }