<template>
    <div >
        <div :class="{ 'invisible': isInvisible }">
            <p  class="text-right text-xs">
            <span
                :class="{
                'text-danger': checkItemLength(item) > maxLength,
                'text-success': checkItemLength(item) <= maxLength,
                }"
            >{{ checkItemLength(item) }}</span>
            /{{ maxLength }} {{ maxChars }}
            </p>

        </div>
    </div>
</template>

<script>

export default { 
    name:'charCounter',    
    props: {
        item: {
            type: String,
            required: true,
        },
        maxLength: {
            type: Number,
            required: true,
            default: 100
        },
        isInvisible: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data(){
        return {
            maxChars:this.$t('resorts.touristic-objects.form.maxChars')
        }
    },
    methods:{ 
        checkItemLength(item){  return item === undefined ? 0 : item.length}
    }


}
</script>